<template>
  <div
    :class="[mobileMode ? 'mobile' : null, 'tools-panel']" :style="`width: ${width}px;` + (mobileMode ? 'z-index: 999;' : '')"
    v-loading="isDynamicFiltersLoading || interactiveProcessing"
  >
    <div class="resizer" @mousedown.stop="startResize">
      <font-awesome-icon icon="grip-lines-vertical"/>
    </div>
    <el-tabs :class="['tool-tabs', isMobile]" :tab-position="'right'" :style="{height: '100%', width: 'calc(100% - 10px)'}" ref="tabs" v-model="tabActiveName">
      <el-tab-pane :style="{ height: '100%' }" name="layers">
        <span slot="label">
          <div style="width:44px; height:44px;  background: no-repeat url('/icon/differentIcons/layers.svg') center;"></div>
        </span>
        <div>
          <div style="display: inline-flex;">
            <div
              :class="['layer-button', isLayerModeSort ? 'layerButtonShow' : 'layerSortButtonShow', isMobile]"
              @click="changeLayerViewMode"></div>
            <div :class="['pane-header', isMobile]">
              Слои
            </div>
            <div
              v-if="!isShowSearchString"
              class="el-icon-search search-button"
              @click="showSearchString"
            ></div>
          </div>
          <div
            class="close-button"
            @click="$emit('show-tools-panel')"
            v-if="mobileMode"
          >
            <div class="el-icon-close"></div>
          </div>
        </div>
        <div class="layerFloat">
          <el-scrollbar :style="{height:'calc(100% - 72px)', width: '100%', overflowX: 'hidden'}" wrap-style="overflow-x:hidden;">
            <div
              v-if="!isLayerModeSort && isShowSearchString"
              style="padding: 0px 10px 5px 10px;"
            >
              <el-input
                v-model="layerSearchText"
                size="small"
                suffix-icon="el-icon-search"
                clearable
                @clear="clearLayerSearchTextHandler"
              ></el-input>
            </div>
            <layers-tree
              v-show="!isLayerModeSort"
              key="tree"
              ref="tree"
              :layers="layers"
              :expandLayersByDefault="expandLayersByDefault"
              :showLayersOpacity="showLayersOpacity"
              :searchText="layerSearchText"
              :is-fullscreen="isFullscreen"
              :hide-layers="hideLayers"
              :checked="checked"
              :active-layer-guid="activeLayerGuid"
              :context-menu-settings="contextMenuSettings"
              @layer-select="(data, checked) => $emit('layer-select', data, checked)"
              @change-layer-opacity="(layer, opacity) => $emit('change-layer-opacity', layer, opacity)"
              @calculate-features="(layer) => $emit('calculate-features', layer)"
              @focus-on-layer="(layer) => $emit('focus-on-layer', layer)"
              @export-layer="(layer) => $emit('export-layer', layer)"
              @click-on-layer="(layerGuid) => $emit('click-on-layer', layerGuid)"
              @layer-separated="(layerGuid, separatedGuids) => $emit('layer-separated', layerGuid, separatedGuids)"
              @layers-loaded="addLoadedLayers"
            ></layers-tree>
            <layers-sort
              v-show="isLayerModeSort"
              key="sort"
              :layers="layers"
              :hide-layers="hideLayers"
            ></layers-sort>
          </el-scrollbar>
        </div>
      </el-tab-pane>
      <el-tab-pane :style="{ height: '100%' }" name="objects_settings">
        <span slot="label">
            <div style="width:44px; height:44px; background: no-repeat url('/icon/differentIcons/options.svg') center;"></div>
          </span>
        <map-interactive-panel
          :interactiveObjects="interactiveObjects"
          :hideLayerButtons="false"
          :mobileMode="mobileMode"
          :emptyOpenObjectByDefault="emptyOpenObjectByDefault"
          :layers="loadedLayers"
          :map="map"
          :is-fullscreen="isFullscreen"
          :featureMetrics="featureMetrics"
          :active-layers="activeLayers"
          :enable-show-geometry-settings="enableShowGeometrySettings"
          :activeLayersLength="activeLayersLength"
          :showToolsPanel="showToolsPanel"
          :vertexNumberingStyle="vertexNumberingStyle"
          v-loading="interactiveProcessing"
          ref="map_interactive_panel"
          @zoom-on-feature="zoomOnFeature"
          @delete-geometry="deleteGeometry"
          @export="exportFromToolsPanel"
          @open-card="openCard"
          @save-geometry="saveGeometry"
          @interactive-processing="interactiveProcessingHandler"
          @intersection-feature="$emit('intersection-feature', $event)"
          @copy-geometry="$emit('copy-geometry', $event)"
          @show-tools-panel="$emit('show-tools-panel')"
        >
        </map-interactive-panel>
      </el-tab-pane>
      <el-tab-pane >
        <span slot="label">
          <div style="width:44px; height:44px; background: no-repeat url('/icon/differentIcons/filter.svg') center;"></div>
        </span>
        <dynamic-filters-pane
          :activeLayers="activeLayers"
          :activeLayersLength="activeLayersLength"
          @apply-filter="applyFilter"
          @change-loading="changeDynamicFiltersLoading"
        ></dynamic-filters-pane>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import LayersTree from './LayersTree'
import DynamicFiltersPane from './DynamicFiltersPane/index'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAlignJustify, faStream, faGripLinesVertical, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import LayersSort from '@/components/InterfaceEditor/components/basic/Map/ToolsPanel/LayersSort'
import VertexEditPanel from '@/services/MapEditor/infrastructure/components/VertexEditPanel'
import MapInteractivePanel from '@/core/infrastructure/components/MapInteractivePanel'

library.add(faAlignJustify, faStream, faGripLinesVertical, faLayerGroup)

const SELECTION_MODE = {
  POINT: 'singleclick',
  RECTANGLE: 'rectangle',
  POLYGON: 'polygon'
}
const OBJECT_VIEW_MODE = {
  PROPERTIES: 'properties',
  GEOMETRY: 'geometry'
}
export default {
  name: 'layers-panel',
  components: {DynamicFiltersPane, LayersSort, LayersTree, FontAwesomeIcon, VertexEditPanel, MapInteractivePanel },
  props: {
    layers: {
      type: Array,
      default: () => []
    },
    expandLayersByDefault: {
      type: Boolean,
      default: false
    },
    map: null,
    toolsPanelWidth: {
      type: Number,
      default: 400
    },
    showLayersOpacity: {
      type: Boolean,
      default: false
    },
    interactiveObjects: {
      type: Array,
      default: []
    },
    mobileMode: {
      type: Boolean,
      default: false
    },
    activeLayers: {
      type: Array,
      default: () => {
        return []
      }
    },
    activeLayersLength: {
      type: Number,
      default: 0
    },
    isFullscreen: {
      type: Boolean,
      default: false
    },
    featureMetrics: {
      type: Object,
      default: function () {
        return {
          units: 'meters',
          enable: false,
          enableSwitch: false
        }
      }
    },
    hideLayers: {
      type: Array,
      default: () => []
    },
    activeLayerGuid: {
      type: String | null,
      default: null
    },
    enableShowGeometrySettings: {
      type: Boolean,
      default: true
    },
    contextMenuSettings: {
      type: Array
    },
    showToolsPanel: {
      type: Boolean,
      default: null
    },
    vertexNumberingStyle: {
      type: Object,
      default: null
    }
  },
  computed: {
    isMobile: function () {
      return this.mobileMode ? 'mobile' : null
    }
  },
  data () {
    return {
      width: this.toolsPanelWidth,
      startResizePosition: 0,
      selectionMode: SELECTION_MODE.POINT,
      objectsAccordion: [],
      tabActiveName: 'layers',
      standardCardFields: {},
      geometryTable: [],
      isLayerModeSort: false,
      openLayersByDefault: [],
      openObjectsByDefault: {},
      emptyOpenObjectByDefault: {},
      layerSearchText: null,
      isShowSearchString: false,
      interactiveProcessing: false,
      isDynamicFiltersLoading: false,
      checked: [],
      loadedLayers: []
    }
  },
  watch: {
    layers: function (newValue) {
      this.$set(this, 'emptyOpenObjectByDefault', this.getEmptyOpenObjectsByDefault())
    }
  },
  provide () {
    return {
      toolsPanelHeight: this.getClientHeight
    }
  },
  methods: {
    addLoadedLayers (layers) {
      this.loadedLayers.push(...layers)
      //this.$emit('layers-loaded', layers)
      this.$set(this, 'emptyOpenObjectByDefault', this.getEmptyOpenObjectsByDefault())
    },
    enterEditGeometry (geom, layer) {
      this.$refs.map_interactive_panel.enterEditGeometry(geom, layer)
    },
    getClientHeight () {
      if (this.$el)
        return this.$el.clientHeight
      return 0
    },
    changeDynamicFiltersLoading (isLoading) {
      this.isDynamicFiltersLoading = isLoading
    },
    interactiveProcessingHandler (isProcessing) {
      this.interactiveProcessing = isProcessing
    },
    clearLayerSearchTextHandler () {
      this.isShowSearchString = false
    },
    showSearchString () {
      this.isShowSearchString = true
    },
    setCheckOnLayer(layerGuid, isCheck) {
      this.$refs.tree.setCheckOnLayer(layerGuid, isCheck)
    },
    exportFromToolsPanel(type) {
      this.$emit('export', type)
    },
    zoomOnFeature(feature) {
      this.$emit('zoom-on-feature', feature)
    },
    getEmptyOpenObjectsByDefault: function () {
      let resultObject = {}
      this.layers.forEach((layer) => {
        this.getLayerAndChildrenGuid(layer).forEach((guid) => {
          resultObject[guid] = []
        })
      })
      //resultObject
      this.loadedLayers.forEach(layer => {
        resultObject[layer] = []
      }, this)
      return resultObject
    },
    getLayerAndChildrenGuid (layerItem) {
      let result = []
      if (layerItem.type === 'group') {
        layerItem.children.forEach((child) => {
          result.push(...(this.getLayerAndChildrenGuid(child)))
        })
      } else if (layerItem.type === 'layer') {
        result.push(layerItem.guid)
      }
      return result
    },
    changeLayerViewMode () {
      this.isLayerModeSort = !this.isLayerModeSort
    },
    startResize (event) {
      document.addEventListener('mousemove', this.resize)
      document.addEventListener('mouseup', this.stopResize)
      this.startResizePosition = event.clientX
    },
    resize (event) {
      event.preventDefault()
      const delta = event.clientX - this.startResizePosition
      if ((this.width + delta) > 150) {
        this.width = this.width + delta
        this.startResizePosition = event.clientX
      }
      this.$refs['tabs'].$refs['nav'].$el.style.left = this.width
    },
    stopResize () {
      document.removeEventListener('mousemove', this.resize)
      document.removeEventListener('mouseup', this.stopResize)
      this.$emit('tools-panel-resized', this.width)
    },
    deleteGeometry (item) {
      this.$emit('delete-geometry', item)
    },
    expandOneItem (layers) {
      layers.forEach((layerItem) => {
        if (layerItem.type === 'layer') {
          let selected = this.openObjectsByDefault.find((item) => { return item.guid === layerItem.guid })
          if (selected.selectedFields.length === 1) {
            selected.selectedFields.splice(0, selected.selectedFields.length, '0')
          } else {
            selected.selectedFields.splice(0, selected.selectedFields.length)
          }
        } else if (layerItem.type === 'group') {
          this.expandOneItem(layerItem.children)
        }
      })
    },
    openCard (item) {
      this.$emit('open-card', item)
    },
    openTabByName (name) {
      this.tabActiveName = name
    },
    saveGeometry (item, geometry, layerSettings) {
      this.$emit('save-geometry', item, geometry, layerSettings)
    },
    applyFilter (layer, isNeedFitLayer) {
      this.$emit('apply-filter', layer, isNeedFitLayer)
    }
  }
}
</script>

<style>
  .objectItemGeometry .el-table__body-wrapper {
    height: auto !important;
  }
  .objectItemGeometry .el-col-24 {
    width: auto;
  }
</style>
<style scoped>
  .search-button {
    font-size: 18px;
    line-height: 28px;
    padding: 10px 0 10px 2px;
    cursor: pointer;
  }

  .tool-tabs.mobile /deep/ .el-tabs__header {
    display: none;
  }
  .pane-header-container {
    display: inline-flex;
    flex-grow: 50;
  }
  .pane-header {
    padding: 10px;
    font-size: 24px;
    line-height: 28px;
    font-weight: 500;
    flex-grow: 50;
  }
  .close-button {
    float: right;
    cursor: pointer;
    margin: 11px 10px 0;
  }
  .pane-header.mobile {
    padding: 3px 0 3px 10px;
    font-size: 20px;
  }
  .selected-button {
    background: #E5F5FF;
    border: 1px solid #80C5F9;
    box-sizing: border-box;
  }
  .el-tabs /deep/ .el-tabs__item {
    padding: 0 !important;
  }
  .el-tabs /deep/ .el-tabs__header {
    border-radius: 0px 6px 6px 0px;
  }
  .layerFloat {
    float: left;
    width: calc(100% - 5px);
    height: 100%;
  }
  .layer-button {
    width: 24px;
    height: 24px;
    float: right;
    border-radius: 6px;
    margin: 11px 0 11px 11px;
  }
  .mobile.layer-button {
    float: left;
    /*margin: 11px;*/
    margin: 3px 0 3px 6px;
  }

  .layerButtonShow {
    background: no-repeat url("/icon/differentIcons/layers.svg") center;
  }
  .layerSortButtonShow {
    background: no-repeat url("/icon/differentIcons/layers-sort.svg") center;
  }
  .el-table {
    font-size: 13px;
    width: max-content;
    position: relative;
    top: 5px;
    margin-bottom: 5px;
  }
  .el-table /deep/ .cell {
    word-break: break-word;
  }
  .el-table /deep/ td {
    padding: 2px;
  }
  .el-table /deep/ th {
    padding: 4px 2px;
  }
  .openCardButton {
    width: auto;
    padding: 3px 10px;
    /*background-color: #f0f0f0;*/
    border-radius: 6px;
    margin: 6px;
    display: block;
    float: left;
    cursor: pointer;
    text-align: center;
    /*box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
    border-color: rgba(0, 0, 0, 0.33);
    */
    color: #208be9;
  }


  .cardMapItem {
    width: 100%;
    display: flow-root;
    padding-left: 10px;
  }
  .cardMapItemHeader {
    background-color: aliceblue;
    border-bottom-width: 2px;
    border-bottom-style: groove;
  }
  .cardMapItemName {
    width: 50%;
    display: inline-block;
  }
  .cardMapItemValue {
    width: 50%;
    display: inline-block;
  }
  .el-icon-date {
    height: 25px;
    width: 25px;
  }
  .el-collapse {
    border: none;
  }
  .el-collapse /deep/ .el-collapse-item__wrap {
    border: none;
    overflow: visible;
  }
  .el-collapse /deep/ .el-collapse-item__arrow {
    left: -26px;
    position: absolute;
  }
  .el-collapse /deep/ .el-collapse-item__header {
    left: 20px;
    position: relative;
    height: 36px;
    line-height: 36px;
    border: none;
    white-space: nowrap;
    font-weight: 400;
  }


  .objectPanel {
    display: block;
    border-radius: 6px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
    height: 45px;
    border-color: rgba(0, 0, 0, 0.33);
    margin: 10px;
    width: max-content;
  }
  .objectPanel /deep/ .button {
    display: inline-block;
    width: 45px;
    height: 45px;
    background: white;
    cursor: pointer;
    position: relative;
    margin: 0;
    float: left;
  }
  .button svg {
    color: #6E7B87;
    font-size: 16px;
    padding: 14px;
  }
  .tools-panel {
    background: #FFFFFF;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    border-top: 6px solid #4FA9F3;
  }
  .tools-panel /deep/ .el-tabs__content {
    height: 100%;
    margin: 10px 0px 5px 5px;;
  }
  .tools-panel /deep/ .el-tabs__content .el-tab-pane {
    height: 100%;
  }
  .tools-panel /deep/ .el-tabs__header {
    height: auto !important;
    right: -44px;
    width: 44px;
    position: absolute;
  }
  .tools-panel /deep/ .el-tabs__header .el-tabs__nav:last-child {
    border-radius: '0px 0px 6px 0px';
  }
  .tools-panel.mobile {
    left: 0px;
    top: 0px;
    bottom: 0px;
    height: 100%;
    border-radius: 0;
    border-top: 0;
  }
  .title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    color: #0D0F11;
    padding-left: 15px;
    padding-top: 2px;
    padding-bottom: 4px;
    border-bottom: 1px solid #E9EBED;
  }
  .button {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: white;
    cursor: pointer;
    text-align: center;
    float: right;
    margin-right: 2px;
  }
  .button svg {
    color: #6E7B87;
    font-size: 16px;
  }
  .button:hover svg {
    color: #409EFF;
  }
  .resizer {
    position: absolute;
    right: 2px;
    top: calc(50% - 8px);
    cursor: ew-resize;
  }
  .resizer svg {
    color: #6E7B87;
    font-size: 16px;
  }
</style>
